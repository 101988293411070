import React, { useEffect, useState } from "react";
import SectionTitle from "../../Common/Main/SectionTitle";
import SupportIcon from "@/public/icons/SupportIcon.svg";
import IconButton from "../../Common/Buttons/IconButton";
import MultiSearchInput from "../../Common/Inputs/MultiSearchInput";
import Link from "next/link";
import { useRouter } from "next/router";
import ClientPagination from "../../Common/Pagination/ClientPagination";
import ServerPagination from "../../Common/Pagination/ServerPagination";
import FAQItem from "../FAQ/FAQItem";
import { openSupport } from "@/utils/functions";
import useTranslation from "@/hooks/useCustomTranslation";

const FaqSection = (props) => {
  const { items = [], categories=[], isFAQPage = false,totalPages=1 } = props;
  const [activeItem, setActiveItem] = useState(1);
  const router=useRouter();
  const {t}=useTranslation("home");

  const handleCategoryClick=(id)=>{
    let newQuery={...router.query,...(id && {category_id:id}),current_page:1}
    if(id==null && newQuery.category_id){
      delete newQuery["category_id"];
    }
    if (newQuery != router.query) {
      router.query = newQuery;
      router.push({pathname:"/faq",query:newQuery,hash: (typeof window !== "undefined") ? window.location.hash : undefined});
    }
  }

  return (
    <>
      {!isFAQPage && <SectionTitle title={t("support")}></SectionTitle>}

      <div className="flex lg:flex-row flex-col">
        <div
          className={`basis-1/3 ${
            isFAQPage ? "pr-8 rtl:pr-auto rtl:pl-8" : "lg:px-16"
          } text-center lg:text-left rtl:lg:text-right`}
        >
          <h3 className="text-black text-4xl text-left rtl:text-right">{t("faqs")} </h3>
          {categories.length>0 &&<ul className="flex flex-col gap-4 my-10">
            <li className={`${router?.query?.category_id == null?"text-blue before:h-full before:w-1 before:bg-blue before:absolute before:left-0 rtl:before:left-auto rtl:before:right-0":"text-black"} relative text-lg cursor-pointer transition-all hover:text-blue pl-5 rtl:pl-auto rtl:pr-5 font-open-sans rtl:font-dana-r font-bold`} onClick={()=>handleCategoryClick(null)}>{t("faqs_all")}</li>
            {categories.map((category,idx)=><li key={idx} className={`${router?.query?.category_id == category.id?"text-blue before:h-full before:w-1 before:bg-blue before:absolute before:left-0 rtl:before:left-auto rtl:before:right-0":"text-black"} relative  cursor-pointer transition-all hover:text-blue pl-5 rtl:pl-auto rtl:pr-5 text-lg font-open-sans rtl:font-dana-r font-bold`} onClick={()=>handleCategoryClick(category.id)}>{category.name}</li>)}
            
          </ul>}
          <p className="font-open-sans rtl:font-dana-r text-base text-gray-dark text-justify my-5">
            {
              t("faq_desc")
            }
          </p>
          <IconButton
            type={"PRIMARY"}
            icon={SupportIcon}
            label={t("online_support")}
            onClick={()=>openSupport()}
          ></IconButton>
        </div>
        <div className="basis-2/3 lg:gap-14 flex flex-col">
          {isFAQPage && (
            <MultiSearchInput
              hasCategory={false}
              hasShipTo={false}
              placeholder={"Search in FAQs"}
            ></MultiSearchInput>
          )}
          {/* <div className="space-y-1 bg-gray-light bg-opacity-25 rounded-lg lg:p-5 p-1 mt-14 lg:mt-0">
          
            
          </div> */}
          <ServerPagination totalPages={totalPages} currentPage={router?.query?.current_page?parseInt(router.query.current_page):1} items={items} BaseComp={FAQItem} className="space-y-1 bg-gray-light bg-opacity-25 rounded-lg lg:p-5 p-1 mt-14 lg:mt-0">
          </ServerPagination>
        </div>
      </div>
    </>
  );
};

export default FaqSection;
