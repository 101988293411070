import React from 'react'
import Box from "@/public/icons/Box.svg";

const FeatureCard = (props) => {
  const {title='',desc='',count=100,icon=''}=props;
  return (
    <div className='flex items-center flex-col'>
      <div className='flex lg:flex-col flex-row items-center'>
        <span className='flex'>
        {icon && icon!=='' && <span className='transform scale-75 lg:transform-none lg:scale-100' dangerouslySetInnerHTML={{__html:icon}}></span>}
        </span>
        
        <div className='flex flex-col items-start lg:items-center'>
          {/* <strong className='text-orange text-4xl py-5'>{count}</strong> */}
          <h5 className='text-4xl text-orange mt-5'>{title}</h5>
        </div>
      </div>
        
        <p className='font-open-sans rtl:font-dana-r text-base text-gray-dark lg:text-center text-left text-justify pt-2'>{desc}</p>
    </div>
  )
}

export default FeatureCard