import React from 'react'
import ToastNormalIcon from "@/public/icons/ToastNormalIcon.svg";
import CloseToastIcon from "@/public/icons/CloseToastIcon.svg";
import { modal } from '@/utils/modal';
import IconButton from '../Buttons/IconButton';
import { useTranslation } from 'next-i18next';

const ConfirmModal = (props) => {
      const { title='',message='',yesText="Yes",noText="No",onConfirm} = props;
      return (
      <div onClick={()=>modal.remove()} className='bg-black bg-opacity-80 w-screen h-screen fixed left-0 rtl:right-0 rtl:left-auto top-0 flex items-center justify-center'>
      <div className="bg-white w-[450px] h-auto rounded-lg z-50" onClick={(e)=>e.stopPropagation()}>
      <img src={`/images/bg-normal.png`} className="w-full h-6"></img>
      <div className="flex items-center px-4 py-2.5">
        <span className={`[&>path]:stroke-black`}><ToastNormalIcon></ToastNormalIcon></span>
        <h6 className={`text-base pl-5 rtl:pl-0 rtl:pr-5 text-black`}>{title}</h6>
        <CloseToastIcon className="ml-auto rtl:mr-auto rtl:ml-0 cursor-pointer" onClick={()=>modal.remove()}></CloseToastIcon>
      </div>
      <div
        className={`h-[1px] w-full bg-gray-light-6 animate-grow `}
      ></div>
      <div className="px-4 pb-4 pt-2 text-sm font-open-sans rtl:font-dana-r text-gray-dark-2">
      {message}
      </div>
      <div className='flex items-center gap-x-4 justify-center my-4'>
            <IconButton label={yesText} type={"PRIMARY"} onClick={onConfirm}></IconButton>
            <IconButton label={noText} type={"OUTLINE"} onClick={()=>modal.remove()}></IconButton>
      </div>
    </div>
    </div>
  )
}

export default ConfirmModal