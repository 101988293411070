import React, { Component } from 'react';

class ImageMultiSrc extends Component {
    state = {
        imageName: "",
        webpUrl: "",
        baseUrl: this.props.src,
        errorCount: 0
    }

    componentDidMount() {
        this.setImage();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.src && prevProps.src && this.props.src != prevProps.src) {
            this.setState({ errorCount: 0 });
            this.setImage();
        }
    }


    setImage () {
        if(this.props.src){
            let extension = this.props.src.split('.')[this.props.src.split('.').length - 1];
            if (extension == "webp") {
                
            }
            let imageName = this.props.src.split('.').slice(0, -1).join('.');
            this.setState({
                imageName,
                webpUrl: imageName + ".webp",
            });
        }else if(this.props.src===null && this.state.webpUrl===""){
            this.setState({
                webpUrl:"/images/notFound.jpg",
            });
        } 
    }

    handleError = (e) => {
        if (this.state.errorCount < 2) {
            e.target.onerror = null;
            e.target.src = this.props.src;
            this.setState({ errorCount: this.state.errorCount + 1 })
        }else if(this.state.errorCount==2){
            e.target.onerror = null;
            e.target.src = "/images/notFound.jpg";
        }
    }
    render() {
        
        return (
            <img {...!this.props.noLazy && {loading:'lazy'}} {...this.props} onError={(e) => this.handleError(e)} src={this.state.webpUrl}>
            </img> 
        );
    }
}

export default ImageMultiSrc;