import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(backend) // Using backend plugin to load translations
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // Detect language from URL, cookies, localStorage, etc.
    detection: {
      order: ['path', 
       // 'cookie', 'localStorage', 'sessionStorage', 'navigator'
      ],
      //caches: ['cookie'],
    },    
    // Specify the path to your translation files
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
