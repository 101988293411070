import React from 'react'
import GoToTopIcon from "@/public/icons/GoToTopIcon.svg";


const GoToTop = () => {
const isBrowser = () => typeof window !== 'undefined'; //The approach recommended by Next.js

const scrollToTop=()=> {
    if (!isBrowser()) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
  return (
    <div id='go-to-top' className='bg-blue w-8 h-8 opacity-0 overflow-hidden cursor-pointer transition-opacity rounded-md flex items-center justify-center fixed bottom-9 right-10 z-[90]' onClick={scrollToTop}>
      <GoToTopIcon ></GoToTopIcon>
    </div>
  )
}

export default GoToTop