import React, { useState } from "react";
import CollapseIcon from "@/public/icons/CollapseIcon.svg";
import ExpandIcon from "@/public/icons/ExpandIcon.svg";

const FAQItem = (props) => {
      const {answer='',question='',id=-1}=props;
      const [isActive,setIsActive]=useState(false);
  return (
    <div className="bg-gray-light-3 p-5 rounded-lg my-3 lg:my-1 select-none group cursor-pointer" onClick={()=>setIsActive(!isActive)}>
      <h5
        className={`${
            isActive ? "text-blue-2" : "text-black"
        } group-hover:text-blue-2 transition-all flex items-center  font-open-sans rtl:font-dana-r font-bold text-base`}
        
      >
        <span>{question}</span>
        {isActive ? (
          <CollapseIcon className="flex-none ml-auto rtl:mr-auto rtl:ml-0"></CollapseIcon>
        ) : (
          <ExpandIcon className="flex-none ml-auto rtl:mr-auto rtl:ml-0"></ExpandIcon>
        )}
      </h5>
      <div
        className={`overflow-hidden transition-all ${
            isActive? "max-h-96" : "max-h-0"
        }`}
      >
        <p className="font-open-sans rtl:font-dana-r text-base text-gray-dark mt-5">
          {answer}
        </p>
      </div>
    </div>
  );
};

export default FAQItem;
