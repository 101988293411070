import React from "react";
import IconButton from "../Buttons/IconButton";
import Link2Icon from "@/public/icons/Link2Icon.svg";
import DiscountIcon from "@/public/icons/DiscountIcon.svg";
import ImageMultiSrc from "./ImageMultiSrc";

const BannerItem = (props) => {
  return (
    <div className="relative h-full rounded-lg overflow-hidden">
      <ImageMultiSrc
        noLazy={true}
        className="w-full h-[408px] object-cover"
        src={props.image}
        alt={props.title}
      ></ImageMultiSrc>
      <div className="absolute top-0 flex flex-col justify-center z-10 lg:w-1/2 h-full lg:ml-24 ml-4 rtl:ml-0 rtl:lg:mr-24 rtl:mr-4">
        {props.festivalText && props.festivalText != "" && (
          <span className="inline-flex text-green text-3xl font-open-sans rtl:font-dana-r items-center font-bold drop-shadow-[0_2.5px_2.5px_rgba(0,0,0,1)]">
            <DiscountIcon className="mr-2 rtl:mr-0 rtl:ml-2"></DiscountIcon>{" "}
            {props.festivalText}
          </span>
        )}
        <h5 className="text-white text-4xl font-bold pt-4 drop-shadow-[0_2.5px_2.5px_rgba(0,0,0,1)]">
          {props.title}
        </h5>
        <h5 className="text-white font-semibold mt-2 font-open-sans rtl:font-dana-r text-xl drop-shadow-[0_2.5px_2.5px_rgba(0,0,0,1)]">
          {props.subTitle}
        </h5>
        <IconButton
          className={"mt-4"}
          type={"PRIMARY"}
          label={props.btnText}
          link={props.btnLink}
          icon={Link2Icon}
        ></IconButton>
      </div>
    </div>
  );
};

export default BannerItem;
