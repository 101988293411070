import UniversalCookies from "universal-cookie";
// import {
//   domain,
//   ssr_domain,
//   email,
//   password,
//   node_domain,
//   node_ssr_domain,
//   lara_email,
//   node_username,
//   node_password,
//   lara_password,
//   cookie_domain,
// } from "./constants";
import { parseCookies } from "./functions";
import axios from "axios";

const domain = process.env.NEXT_PUBLIC_ROOT_URL_OF_BACKEND;
const frontDomain = process.env.NEXT_PUBLIC_ROOT_URL_OF_FRONTEND;

const csr_axios =  axios.create({
  baseURL: domain,
});


export default (req_headers, req_params, context, formData) => {
  const handle_app_token = () => {
    if (context) {
      return parseCookies(context.req).app_token || "";
    }else{
      return new UniversalCookies().get("app_token") || "";
    }
  };

  let options = {
    baseURL: domain,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: handle_app_token(),

    },
    ...req_headers,
  };

  options.data = req_params ? { ...req_params } : formData;
  // console.log(options,"options")
  let userToken="";
  let language=req_headers.language?req_headers.language:"en";
  if(context){
    userToken = context.req.cookies.user_token ;
    language= context.locale;
  }else{
    userToken = new UniversalCookies().get("user_token");
  }
  if(userToken && userToken!=""){
    options.headers={...options.headers,"User-Token":userToken}
  }
  if(formData){
    options.headers={...options.headers,'Content-Type': 'multipart/form-data'}
  }
  options.headers={...options.headers,"Language":language}

  return csr_axios(options)
    .then((response) => {
      response.data.isLogin=userToken && userToken!=""?true:false;

      return response.data;
    })
    .catch((error) => {

      if(!error.response)
      {
        console.log( error);
      }

      if (error.response.status == 401) {

        return csr_axios({
          url: "api/client",
          baseURL:frontDomain,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then((response) => {
            if (context) {
              context.res.setHeader(
                "Set-Cookie",
                `app_token=${"Bearer " + response.data.token}`
              );
              options.headers["Authorization"] =
                "Bearer " + response.data.token;
            } else {
              let d = new Date();
              d.setTime(d.getTime() + 3 * 365 * 24 * 60 * 60 * 1000);

              new UniversalCookies().set(
                "app_token",
                "Bearer " + response.data.token,
                {
                  domain: req_headers.host,
                  path: "/",
                  expires: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 4)
                  ),
                }
              );
              options.headers["Authorization"] =
                "Bearer " + response.data.token;
            }
            return csr_axios(options)
              .then((response) => {
                response.data.isLogin=userToken && userToken!=""?true:false;
                return response.data;
              })
              .catch((err) => {
                throw err.response.data;
              });
          })
          .catch((error) => {
            throw error;
          });
      } else{
        throw error.response.data;
      }
    }
  );
};
