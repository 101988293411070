import UniversalCookies from "universal-cookie";

export function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <=
      (window.innerWidth + 1 || document.documentElement.clientWidth)
  );
}

export function makeUrl(url) {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    url = "http://" + url;
  }
  return url;
}

export function isLogin() {
  let userToken = new UniversalCookies().get("user_token");
  return userToken && userToken != "" ? true : false;
}

export function openSupport() {
  let script = document.createElement("script");
  script.innerHTML = `     
    !function(){var i="${document.documentElement.lang=="en"?'qrDVXx':'R9az4I'}",a=window,d=document;function g(){var g=d.createElement("script"),s="https://www.goftino.com/widget/"+i,l=localStorage.getItem("goftino_"+i);g.async=!0,g.src=l?s+"?o="+l:s;d.getElementsByTagName("head")[0].appendChild(g);}"complete"===d.readyState?g():a.attachEvent?a.attachEvent("onload",g):a.addEventListener("load",g,!1);}();
    `;
  script.addEventListener("load", (ev) => {
    console.log("script loaded");
  });
  script.addEventListener("error", (ev) => {
    console.log("error");
  });
  document.body.appendChild(script);
  console.log("script apended");
  const supportBtn = document
    .querySelector("#goftino_w")
    ?.contentWindow?.document?.body?.querySelector(".widget-icon");
  if (supportBtn && supportBtn.style) supportBtn.style.display = "none";
  if (supportBtn) supportBtn.click();
}

export function parseCookies(request) {
  var list = {},
    rc = request.headers.cookie;

  rc &&
    rc.split(";").forEach(function (cookie) {
      var parts = cookie.split("=");
      list[parts.shift().trim()] = decodeURI(parts.join("="));
    });
  return list;
}

export const validateEmail = (email) => {
  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = pattern.test(email);
  return result;
};

export const onEnteringEnglish = (e) => {
  let keyCode = e.keyCode === 0 ? e.charCode : e.keyCode;
  // eslint-disable-next-line
  let ret =
    (keyCode >= 46 && keyCode <= 58) ||
    (keyCode >= 63 && keyCode <= 90) ||
    (keyCode >= 97 && keyCode <= 122) ||
    keyCode === 32;
  if (!ret) {
    e.preventDefault();
  }
};

export const english_text = (str) => {
  var p = /^[\u0600-\u06FF\s]+$/;
  if (p.test(str)) {
    return false;
  }
  return true;
};

// export function printDiv() {
//     var divContents = document.getElementById("GFG").innerHTML;
//     var a = window.open('', '', 'height=500, width=500');
//     a.document.write('<html>');
//     a.document.write('<body > <h1>Div contents are <br>');
//     a.document.write(divContents);
//     a.document.write('</body></html>');
//     a.document.close();
//     a.print();
// }

export const validateNameLastName = (name) => {
  const pattern = /^[^0-9]{2,} [^0-9]{2,}$/;
  const result = pattern.test(name);
  return result;
};

export const validatePhone = (num) => {
  const pattern = /^09\d{9}$/;
  const result = pattern.test(num);
  return result;
};

export const validatePassword = (password) => {
  const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
  // const pattern = /^.{8,}$/
  const result = pattern.test(password);
  return result;
};

export const validateHttpsUrl = (url) => {
  const pattern =
    /http[s]?:\/\/(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}/;
  const result = pattern.test(url);
  return result;
};

export const validateUrl = (url) => {
  const pattern =
    /(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}/;
  const result = pattern.test(url);
  return result;
};

export const showValidUrl = (url) => {
  const pattern = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/gim;
  const result = url.match(pattern);
  return result;
};

export const onEnteringPhone = (event) => {
  if (
    (event.key == "0" ||
      event.key == "1" ||
      event.key == "2" ||
      event.key == "3" ||
      event.key == "4" ||
      event.key == "5" ||
      event.key == "6" ||
      event.key == "7" ||
      event.key == "8" ||
      event.key == "9") &&
    event.target.value.length <= 10
  ) {
  } else {
    event.preventDefault();
  }
};

export const onEnteringEmail = (event) => {
  if (event.target.value.length > 64) {
    event.preventDefault();
  }
};

export const convertTimestampToPersianDate = (timestamp) => {
  return new Date(parseInt(timestamp) * 1000).toLocaleDateString("fa-IR");
};

export const IsJsonString = (str) => {
  let json_data = null;
  try {
    json_data = JSON.parse(str);
  } catch (e) {
    return str;
  }
  return json_data;
};

export const changePersianNumbersToEnglish = (value) => {
  return value
    .toString()
    .split("")
    .map((char) => {
      if (char == "۱") {
        return "1";
      } else if (char == "۲") {
        return "2";
      } else if (char == "۳") {
        return "3";
      } else if (char == "۴") {
        return "4";
      } else if (char == "۵") {
        return "5";
      } else if (char == "۶") {
        return "6";
      } else if (char == "۷") {
        return "7";
      } else if (char == "۸") {
        return "8";
      } else if (char == "۹") {
        return "9";
      } else return char;
    })
    .join("");
};

export function isTabooWord(word) {
  for (let tabooWord of tabooWords) {
    if (word.trim() == tabooWord) {
      return true;
    }
  }
  return false;
}

export function isStopWord(word) {
  for (let stopWord of stopWords) {
    if (word.trim() == stopWord) {
      return true;
    }
  }
  return false;
}

export function checkWord(word) {
  let PersianOrASCII = /[ا-ی]|[a-z]|[0-9]/g;
  return PersianOrASCII.test(word);
}

export const validateURL = (data) => {
  const pattern =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const result = pattern.test(data);
  return result;
};

export function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(
    function () {},
    function () {}
  );
}

export const showPriceWithComma = (price) => {
  if (price) {
    return price
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g)
      .join(",")
      .split("")
      .reverse()
      .join("");
  } else {
    return price;
  }
};

export const nFormatter = (num, digits, lang = "en") => {
  let lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  if (lang == "fa") {
    lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "هزار" },
      { value: 1e6, symbol: "میلیون" },
      { value: 1e9, symbol: "میلیارد" },
      { value: 1e12, symbol: "تیلیارد" },
      { value: 1e15, symbol: "" },
      { value: 1e18, symbol: "" },
    ];
  }
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const isElementInViewport = (el) => {
  var rect = el?.getBoundingClientRect();
  if (window.innerWidth > 768) {
    return (
      rect &&
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom - 100 <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      Math.trunc(rect.right) <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  } else {
    return (
      rect &&
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom - 400 <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      Math.trunc(rect.right) <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }
};

export const manage_animation = (state, elemnt, type_of_animation) => {
  const my_index = state.findIndex((ikey) => ikey.id === elemnt);
  if (my_index !== -1) {
    return type_of_animation;
  } else {
    return null;
  }
};

export const scrollLoop = (
  first_item,
  second_item,
  first_item_multiple,
  second_item_multiple
) => {
  let yScrollPosition;

  // Create the translateY function
  function setTranslate(yPos, el) {
    el.style.transform = `translateY(${yPos / 2}px)`;
  }
  yScrollPosition = window.pageYOffset;
  // We only change the Y variable
  setTranslate(yScrollPosition * first_item_multiple, first_item);
  setTranslate(yScrollPosition * second_item_multiple, second_item);
};

export const validate_url_domain_name = (value) => {
  if (domain_names.find((d) => d.Domain === "." + value)) {
    return true;
  }
  return false;
};

export const domainFromUrl = (url) => {
  var result;

  var match;

  if (
    (match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
    ))
  ) {
    result = match[1];

    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }

  return result;
};

export function getUrlParts(fullyQualifiedUrl) {
  var url = {},
    tempProtocol;
  var a = document.createElement("a");
  // if doesn't start with something like https:// it's not a url, but try to work around that
  if (fullyQualifiedUrl.indexOf("://") == -1) {
    tempProtocol = "https://";
    a.href = tempProtocol + fullyQualifiedUrl;
  } else a.href = fullyQualifiedUrl;
  var parts = a.hostname.split(".");
  url.origin = tempProtocol ? "" : a.origin;
  url.domain = a.hostname;
  url.subdomain = parts[0];
  url.domainroot = "";
  url.domainpath = "";
  url.tld = "." + parts[parts.length - 1];
  url.path = a.pathname.substring(1);
  url.query = a.search.substr(1);
  url.protocol = tempProtocol
    ? ""
    : a.protocol.substr(0, a.protocol.length - 1);
  url.port = tempProtocol
    ? ""
    : a.port
    ? a.port
    : a.protocol === "http:"
    ? 80
    : a.protocol === "https:"
    ? 443
    : a.port;
  url.parts = parts;
  url.segments = a.pathname === "/" ? [] : a.pathname.split("/").slice(1);
  url.params = url.query === "" ? [] : url.query.split("&");
  for (var j = 0; j < url.params.length; j++) {
    var param = url.params[j];
    var keyval = param.split("=");
    url.params[j] = {
      key: keyval[0],
      val: keyval[1],
    };
  }
  // domainroot
  if (parts.length > 2) {
    url.domainroot = parts[parts.length - 2] + "." + parts[parts.length - 1];
    // check for country code top level domain
    if (
      parts[parts.length - 1].length == 2 &&
      parts[parts.length - 1].length == 2
    )
      url.domainroot = parts[parts.length - 3] + "." + url.domainroot;
  }
  // domainpath (domain+path without filenames)
  if (url.segments.length > 0) {
    var lastSegment = url.segments[url.segments.length - 1];
    var endsWithFile = lastSegment.indexOf(".") != -1;
    if (endsWithFile) {
      var fileSegment = url.path.indexOf(lastSegment);
      var pathNoFile = url.path.substr(0, fileSegment - 1);
      url.domainpath = url.domain;
      if (pathNoFile) url.domainpath = url.domainpath + "/" + pathNoFile;
    } else url.domainpath = url.domain + "/" + url.path;
  } else url.domainpath = url.domain;
  return url;
}


export function calculateDefaultValueArchive(query,categories){
    
  if (query.params && Array.isArray(query.params) && query.params.length > 0) {
    let type = "";
    let last = query.params[query.params.length-1];    
    let allTypes = ['purchase-request','sell-offer','ready-to-ship'];
    if (allTypes.includes(last)) {
      type = last;
      query.params.pop();
    }
    //console.log("type:",type);
    
    let first = query.params[0];
    let second = query.params[1];

    switch (type) {
      case "purchase-request":
        query = { ...query, ad_type: 1 };
        break;
      case "sell-offer":
        query = { ...query, ad_type: 3 };
        break;
      case "ready-to-ship":
        query = { ...query, ad_type: 2 };
        break;
    }

    if(first) query = { ...query, category_slug: first }
    if(second) query = { ...query, sub_category_slug: second }
  }

    /*
  if(query && query.params && query.params.length>1){
    let type=query.params[0];
    let value=query.params[1];
    switch (type) {
      case "type":
        switch (value) {
          case "purchase-request":
            query.ad_type= 1 ;
            break;
          case "sell-offer":
            query.ad_type= 3 ;
            break;
          case "ready-to-ship":
            query.ad_type= 2 ;
            break;
        }
        break;
      case "category":
        let categoryId=categories.find(category=>category.slug==value)
        if(categoryId){
          query.category_id=categoryId.id;
        }
        break;
      case "sub-category":
        query = { ...query, sub_category_slug: value }
        break;
      default:
        isNotFound=true;
    }
  }
*/
  return query;
}