import React, { useState, useRef, useEffect } from 'react';
import Link from "next/link";
import styles from "./SiraafMenu.module.css"; // Import the CSS module

const SiraafMenu = ({ items }) => {

    const [level1ActiveIndex, setLevel1ActiveIndex] = useState(null);
    const [level2ActiveIndex, setLevel2ActiveIndex] = useState(0);


    const level1Click = (index) => {
        setLevel1ActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const level2Click = (index) => {
        setLevel2ActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const truncate = (name) => {
        return name.length > 30 ? `${name.substring(0, 30)}...` : name;
    };

    // Create a ref for the menu
    const menuRef = useRef(null);

    
    // Handle click outside of the menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setLevel1ActiveIndex(null);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
<div ref={menuRef} className={`${styles.siraafmenu} h-full`}>
    <div className={`${styles.level1Box} h-full`}>
        {items && items.map((level1, index) => (
            <div key={index} className={`${styles.level1BoxItem} ${level1ActiveIndex == index ? styles.level1BoxItemActive : styles.level1BoxItemInactive} flex w-full bg-blue mx-0  hover:bg-blue-2 rounded-lg lg:rounded-2xl  text-white px-7 md:ltr:mr-3 md:rtl:ml-3`} onClick={() => level1Click(index)}>
                
                <svg width="24" height="24" viewBox="1 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`ltr:mr-3 rtl:ml-3`}>
                <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.46997 10.6399L12 14.1599L15.53 10.6399" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                        
                <span className={`${styles.menuLabel}`}>{truncate(level1.name)}</span>
            </div>
        ))}
    </div>
    <div>
        {level1ActiveIndex != null && (
            <div className={`${styles.dropdown} block md:flex rounded-xl`}>
                <div className={`${styles.level2Box} w-full md:w-2/5 lg:w-1/4 p-2 max-h-64 overflow-y-scroll md:max-h-none md:overflow-visible rounded-md md:rounded-none [border-width:5px] md:[border-width:0] md:ltr:[border-right-width:2px] md:rtl:[border-left-width:2px] md:[border-style:dotted]`}>
                    {items && items.map((level1, index) => (
                        level1ActiveIndex === index ? (
                            <ul className={styles.menuList}>
                                {level1.items && level1.items.map((level2, index) => (
                                    <li key={index} className={`${styles.level2} ${level2ActiveIndex == index ? styles.level2BoxItemActive : styles.level2BoxItemInactive} p-1 px-2`} onClick={() => level2Click(index)}>
                                        <span className={`${styles.menuLabel}  text-gray-700 hover:text-gray-500`} title={level2.name}>{truncate(level2.name)}</span>
                                    </li>
                                ))}
                            </ul>
                        ) : null
                    ))}
                </div>
                <div className={`${styles.level3Box} w-full md:w-3/5 lg:w-3/4 p-2 1`}>
                    {items && items.map((level1, index) => (
                        level1ActiveIndex === index ? (
                            level1.items && level1.items.map((level2, index) => (
                                level2ActiveIndex === index ? (
                                    <div key={index} className={`${styles.menuContainer} ${level2ActiveIndex == index ? styles.menuContainerActive : styles.menuContainerInactive}`} >
                                        {level2.items && (
                                            <div className={`flex flex-wrap md:flex-row 2`} >
                                                {level2.items.map((level3, subIndex) => (
                                                    <div key={subIndex} className={`mb-4 p-4 w-1/2 md:w-1/3 3`}>
                                                        <span className={`${styles.menuLabel} ${styles.menuLabelL2} flex w-full [align-items:center]`}>

                                                            <svg width="24px" height="24px" viewBox="-6 -6 28 28" fill="#fc9700" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.70711 12.7071L8 16L11.2929 12.7071L8 9.41421L4.70711 12.7071Z" fill="#fc9700"/>
                                                            <path d="M3.29289 11.2929L6.58579 8L3.29289 4.70711L0 8L3.29289 11.2929Z" fill="#fc9700"/>
                                                            <path d="M4.70711 3.29289L8 0L11.2929 3.29289L8 6.58579L4.70711 3.29289Z" fill="#fc9700"/>
                                                            <path d="M12.7071 4.70711L9.41421 8L12.7071 11.2929L16 8L12.7071 4.70711Z" fill="#fc9700"/>
                                                            </svg>

                                                            {level3.name}
                                                        </span>
                                                        {level3.items && (
                                                            <ul className={`{styles.menuList} w-full`}>
                                                                {level3.items.map((level4, subIndex) => (
                                                                    (subIndex <= 8 && 
                                                                    <li key={subIndex} className={`${styles.level2} ltr:ml-7 rtl:mr-7 [list-style-type:circle]`}>
                                                                        <Link href={`/ads/${level2.slug}/${level3.slug}/${level4.slug}`}>
                                                                            <span className={`${styles.menuLabel}  text-gray-700 hover:text-gray-500`} title={level4.name} >{truncate(level4.name)}</span>
                                                                        </Link>
                                                                    </li>
                                                                    )
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ) : null
                            ))
                        ) : null
                    ))}
                </div>
            </div>
        )}            
    </div>       
</div>

 
    );
};

export default SiraafMenu;
