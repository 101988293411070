import React from "react";
import LinkedinIcon from "@/public/icons/LinkedinIcon.svg";
import InstagramIcon from "@/public/icons/InstagramIcon.svg";
import TwitterIcon from "@/public/icons/TwitterIcon.svg";
import IconButton from "../../Common/Buttons/IconButton";
import SupportIcon from "@/public/icons/SupportIcon.svg";
import AnimateLogoSection from "../../Pages/Home/AnimateLogoSection";
import Link from "next/link";
import { openSupport } from "@/utils/functions";
import useTranslation from "@/hooks/useCustomTranslation";

const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <div className="py-4">
      <div className="flex pt-24 lg:-mx-28">
        <div className="lg:basis-1/4 lg:flex hidden flex-col items-center">
          <div>
            <span className="text-white text-2xl">{t("get_in_touch")}</span>
            <ul className="flex flex-row gap-x-5 pl-5 mt-4">
              <li>
                <Link href="#" aria-label={"IRExporter Linkedin"}>
                  <LinkedinIcon></LinkedinIcon>
                </Link>
              </li>
              <li>
                <Link href="#"  aria-label={"IRExporter Instagram"}>
                  <InstagramIcon></InstagramIcon>
                </Link>
              </li>
              <li>
                <Link href="#"  aria-label={"IRExporter Twitter"}>
                  <TwitterIcon></TwitterIcon>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="lg:basis-2/4 basis-1/2 flex items-center justify-center -mt-24 lg:mr-0 mr-8 rtl:lg:ml-0 rtl:ml-8 rtl:mr-auto">
          <AnimateLogoSection></AnimateLogoSection>
        </div>
        <div className="lg:basis-1/4 basis-1/2 flex flex-col items-center">
          <div className="flex flex-col">
            <span className="text-white text-2xl relative whitespace-nowrap">
              {t("online_support")}
              <span className="absolute -right-2 rtl:right-auto rtl:-left-2 rounded-full bg-green w-1 h-1 inline-block"></span>
            </span>
            <IconButton
              className="ml-5 rtl:ml-auto rtl:mr-5 mt-4 whitespace-nowrap"
              type={"SECONDARY"}
              label={t("chat_now")}
              icon={SupportIcon}
              onClick={() => openSupport()}
            ></IconButton>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-y-4 mb-8 lg:-mx-28">
        <div className="basis-1/4 flex lg:flex-col flex-row lg:items-center">
          <div className="lg:w-40 w-full">
            <label className="text-white text-lg">siraaf.com</label>
            <ul className="ml-4 rtl:ml-auto rtl:mr-4 mt-4 inline-flex flex-row gap-3 flex-wrap lg:gap-x-0 lg:flex-col">
              <li>
                <Link
                  href="/about-us"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("about_us")}
                </Link>
              </li>
              <li>
                <Link
                  href="/contact-us"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("contact_us")}
                </Link>
              </li>
              {/* <li>
                <Link
                  href="#"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("support")}
                </Link>
              </li> */}
              {/* <li>
                <Link
                  href="/pricing"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("price_list")}
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="basis-1/4 flex lg:flex-col flex-row lg:items-center">
          <div className="lg:w-40 w-full">
            <label className="text-white text-lg">{t("need_to_learn")}</label>
            <ul className="ml-4 rtl:ml-auto rtl:mr-4 mt-4 inline-flex flex-row gap-3 flex-wrap lg:space-x-0 lg:flex-col">
              <li>
                <Link
                  href="/blogs"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("blogs")}
                </Link>
              </li>
              <li>
                <Link
                  href="/faq"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("faqs")}
                </Link>
              </li>
            
            </ul>
          </div>
        </div>
        <div className="basis-1/4 flex lg:flex-col flex-row lg:items-center">
          <div className="lg:w-40 w-full">
            <label className="text-white text-lg">{t("sources")}</label>
            <ul className="ml-4 rtl:ml-auto rtl:mr-4 mt-4 inline-flex flex-row gap-3 flex-wrap lg:space-x-0 lg:flex-col">
              <li>
                <Link
                  href="/ads/purchase-request"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("purchase_requests")}
                </Link>
              </li>
              <li>
                <Link
                  href="/ads/ready-to-ship"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("ready_to_ship")}
                </Link>
              </li>
              <li>
                <Link
                  href="/ads/sell-offer"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("sales_offers")}
                </Link>
              </li>
              <li>
                <Link
                  href="/ads"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("all_ads")}
                </Link>
              </li>
              <li>
                <Link
                  href="/companies"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("companies")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="basis-1/4 flex lg:flex-col flex-row lg:items-center">
          <div className="lg:w-40 w-full">
            <label className="text-white text-lg">
              {t("advertising_plans")}
            </label>
            <ul className="ml-4 rtl:ml-auto rtl:mr-4 mt-4 inline-flex flex-row gap-3 flex-wrap lg:space-x-0 lg:flex-col">
              <li>
                <Link
                  href="#"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("include_website")}
                </Link>
              </li>
              <li>
                <Link
                  href="#"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("telegram_chanel")}
                </Link>
              </li>
              <li>
                <Link
                  href="#"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("whatsapp_channel")}
                </Link>
              </li>
              <li>
                <Link
                  href="#"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("email_marketing")}
                </Link>
              </li>
              <li>
                <Link
                  href="#"
                  className="text-gray-dark-2 whitespace-nowrap text-base hover:text-white transition relative group"
                >
                  <span className="absolute -left-4 rtl:left-auto rtl:-right-4 top-1.5 rounded-full bg-transparent group-hover:bg-white w-1 h-1 inline-block"></span>
                  {t("submit_ad")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden pt-20 lg:pt-0">
        <div className="absolute top-0 w-20" dangerouslySetInnerHTML={{
              __html: `<img loading="lazy" onClick="window.open('https://trustseal.enamad.ir/?id=371043&amp;Code=UdzqnvlasNeeu8C8nQgZ')" src="https://trustseal.enamad.ir/logo.aspx?id=371043&amp;Code=UdzqnvlasNeeu8C8nQgZ" alt="" style={{"cursor":"pointer"}} id="UdzqnvlasNeeu8C8nQgZ" />`,
            }}>
        
        </div>
      <div className="text-center text-gray-dark-2 whitespace-nowrap text-base ">
        <span className="flex flex-col sm:flex-row items-center justify-center">
          <div>
            <Link
              className="hover:text-white transition"
              href="/pages/terms-and-conditions"
            >
              {t("terms_and_conditions")}
            </Link>{" "}
            -{" "}
            <Link className="hover:text-white transition" href="/pages/privacy-policy">
              {t("privacy")}
            </Link>{" "}
          </div>
          <div>
            <span className="opacity-0 sm:opacity-100 px-1">- </span>
            <Link className="hover:text-white transition" href="/pages/intellectual-property-protection">
              {t("intellectual_property_protection")}
            </Link>{" "}
            -{" "}
            <Link className="hover:text-white transition" href="/pages/listing-conditions">
              {t("listing_conditions")}
            </Link>
          </div>
        </span>
      </div>
      <div className="text-center mt-4">
        <span className="text-white font-open-sans rtl:font-dana-r">
          {t("copy_write")}
        </span>
      </div>
      </div>
    </div>
  );
};

export default Footer;
