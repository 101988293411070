import Toast from "@/src/components/Common/Toast/Toast"
import { render, unmountComponentAtNode } from "react-dom"
import { createRoot } from 'react-dom/client';

export const toast = {

      remove: () => {
        toast.root.unmount()
        toast.currentToast = false
        if(toast.timeout){
          clearTimeout(toast.timeout)
          toast.timeout = null
        }
      },
      root:null,
      currentToast: false,
      timeout: null,
      notify: ( message ,title, options = null) =>{
        
        let duration = 5

        if( options ){
          if( options.duration)
            duration = options.duration
        } 
    
        if(toast.currentToast) { 
          toast.remove()
    
        }
        let trasitionPercentage = 0.3*(100/duration)
        const container = document.getElementById('toast-container');
        toast.root = createRoot(container); 
        // createRoot(container!) if you use TypeScript
        toast.root.render(<Toast 
          message={message} 
          title={title}
          slideIn={true} 
          type={options?.type}
          transitionPercentage={trasitionPercentage} 
          duration={duration} />);
        toast.currentToast = true
        toast.timeout = setTimeout( toast.remove, duration*1000)
      }
    }