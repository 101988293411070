import React, { Children, cloneElement, useEffect, useState } from "react";
import Arrow from "@/public/icons/Arrow.svg";
import { useRouter } from "next/router";

const ServerPagination = (props) => {
  const { items=[],BaseComp=<></>, className = "", pageSize = 5, totalPages=0 ,currentPage=1} = props;
  //console.log('ServerPagination' , items);

  // const countItems = children ? Children.count(children) : 0;
  const router = useRouter();
  const [current,setCurrent]=useState(currentPage);

  const handlePageChange=(page)=>{
    setCurrent(page);
    let newQuery=router.query;
    newQuery={...newQuery,current_page:page}
    router.query = newQuery;
    router.push(router, undefined, { shallow:false,scroll:true });
  }

  useEffect(()=>{
    setCurrent(currentPage);
  },[currentPage]);
  return (
    <>
    <div className={className}>
    {items.length>0
      ?
      items.map((item,idx)=>
        <BaseComp {...item}></BaseComp>
      )
      :
      <h5 className="text-gray-dark text-xl text-center">No Items Found</h5>
    }
    </div>
      {totalPages>1 && <nav aria-label="Page navigation" className="text-center mt-12 select-none">
        <ul className="inline-flex items-center -space-x-px">
          {current > 1 && (
            <li>
              <span
                className="flex items-center justify-center cursor-pointer mx-2 h-10 w-10 rounded-l-md rtl:rounded-r-md rtl:rounded-l-none text-base bg-black text-white group hover:text-blue"
                onClick={() => handlePageChange(current - 1)}
              >
                <span className="sr-only">Previous</span>
                <Arrow className="group-hover:[&>path]:stroke-blue rtl:rotate-180"></Arrow>
              </span>
            </li>
          )}
          {Array.from(
            { length:totalPages },
            (x, i) => i + 1
          ).slice(current>=4?current-2:0,current>=4?current+2:4).map((num) => (
            <li key={num}>
              <span
              
                className={`flex items-center justify-center cursor-pointer mx-2 h-10 w-10 rounded-md text-base hover:text-blue ${
                  current == num
                    ? "text-white bg-black"
                    : "text-black-base bg-gray-light"
                }`}
                onClick={() => handlePageChange(num)}
              >
                {num}
              </span>
            </li>
          ))}

          {current < totalPages && (
            <li>
              <span
                
                className="flex items-center justify-center cursor-pointer mx-2 h-10 w-10 rounded-r-md rtl:rounded-l-md rtl:rounded-r-none text-base bg-black group text-white hover:text-blue"
                onClick={() => handlePageChange(current + 1)}
              >
                <span className="sr-only">Next</span>
                <Arrow
                  className={"group-hover:[&>path]:stroke-blue rotate-180 rtl:rotate-0"}
                ></Arrow>
              </span>
            </li>
          )}
        </ul>
      </nav>
      }
    </>
  );
};

export default ServerPagination;
