import React from "react";
import BannerSlider from "../../Common/Sliders/BannerSlider";
import BannerItem from "../../Common/Main/BannerItem";
import dynamic from "next/dynamic";
import ProfileCard from "../../Common/Cards/ProfileCard";
// const ProfileCard = dynamic(() => import('../../Common/Cards/ProfileCard'), {ssr:false});

const HeroSection = (props) => {
  const {items,userInfo,isLogin=false}=props;

  return (
    <div className="inline-flex justify-between lg:bg-white lg:rounded-2xl rounded-lg overflow-hidden w-full">
      <div className="hidden xl:flex flex-none flex-shrink-0">
        <ProfileCard info={userInfo} isLogin={isLogin} ></ProfileCard>
      </div>
      <div className="flex-none xl:w-[1080px] w-full">
        <div className="md:p-4 md:pl-8 rtl:md:pr-8 rtl:md:pl-4 p-0 pb-10 h-full w-full">
          <BannerSlider items={items} BaseComp={BannerItem} loop={true}></BannerSlider>
        </div>
        
      </div>
     
    </div>
  );
};

export default HeroSection;
