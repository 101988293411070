import React from "react";
import EyeIcon from "@/public/icons/EyeIcon.svg";
import ReadMoreIcon from "@/public/icons/ReadMoreIcon.svg";
import IconButton from "../Buttons/IconButton";
import Link from "next/link";
import FollowersCountIcon from "@/public/icons/FollowersCountIcon.svg";
import CommentIcon from "@/public/icons/CommentIcon.svg";
import ImageMultiSrc from "../Main/ImageMultiSrc";
import useTranslation from "@/hooks/useCustomTranslation";

const BlogCard = (props) => {
  const {title='',excerpt='',slug='',image='',category='',categorySlug='',label='',likeCount=0,commentCount=0,updatedAt=''}=props;
  const {t}=useTranslation("common");
  return (
    <div className="w-full bg-white group rounded-lg overflow-hidden relative">
      
      <Link href={"/blogs/"+slug}>
        <div className="bg-white text-black-2 hover:text-blue-2 transition-all font-open-sans rtl:font-dana-r text-sm font-semibold px-4 py-2 h-14" >
            <h5 className="overflow-hidden text-ellipsis h-10" style={{display:"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}}>{title}</h5>
        </div>
        <div className="w-full h-48 overflow-hidden">
          <ImageMultiSrc
            className="object-cover h-full w-full group-hover:scale-110 transition-all"
            src={image}
            alt={title}
          />
        </div>
        
      </Link>
     
      <div className="p-4">
        <div className="flex items-center">
          <span className="border border-2 border-l-0 rtl:border-l-2 rtl:border-r-0 -ml-4 rtl:ml-auto rtl:-mr-4 rounded-r-lg rtl:rounded-l-lg rtl:rounded-r-none py-0.5 px-2 pl-4 rtl:pl-auto rtl:pr-4 border-blue">
            {label}
          </span>
          <div className="ml-auto rtl:ml-0 rtl:mr-auto flex gap-3">
            <span className='inline-flex items-center text-gray-dark-2'>
                  <FollowersCountIcon className="mr-1 rtl:ml-1 rtl:mr-auto [&>path]:stroke-gray-dark-2"></FollowersCountIcon> {likeCount}
            </span>
            <span className='inline-flex items-center text-gray-dark-2'>
                <CommentIcon className="mr-1 rtl:ml-1 rtl:mr-auto [&>path]:stroke-gray-dark-2"></CommentIcon> {commentCount}
            </span>
          </div>
        </div>
        <span className="flex items-center font-open-sans rtl:font-dana-r block py-2 font-light text-xs text-gray-light-2">
          <span>
            {/*}
          <Link className="hover:text-blue transition-all" href={"/blogs"}>{t("blog_blogs")}</Link> / <Link className="hover:text-blue transition-all" href={"/blogs/"+categorySlug}>{category}</Link>
            {*/}
          </span>
          <span className="ml-auto rtl:mr-auto rtl:ml-0">{updatedAt}</span>
        </span>
        <p className="font-open-sans rtl:font-dana-r text-sm font-light text-justify text-gray-dark pb-2 h-16 text-ellipsis overflow-hidden" style={{display:"-webkit-box","-webkit-line-clamp":3,"-webkit-box-orient":"vertical"}}>
        {excerpt}
        </p>
        <div>
          <IconButton
            className="mr-2 rtl:mr-auto rtl:ml-2"
            type={"PRIMARY"}
            label={t("blog_read_more")}
            icon={ReadMoreIcon}
            color={"blue"}
            link={"/blogs/"+slug}
          ></IconButton>
         
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
