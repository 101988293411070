import React, { useEffect, useRef, useState } from "react";
import TickIcon from "@/public/icons/TickIcon.svg";
import { useFormContext, useWatch } from "react-hook-form";

const CheckBox = (props) => {
const {label,isRequired=false,name='',errorMsg='',className='',onChange=()=>{}} = props;
const [checked, setChecked] = useState(false);
const { register,formState: { errors },setValue,control } = useFormContext(); 
const data=useWatch({control:control});
useEffect(()=>{
  if(checked!=data[name]){
    setChecked(data[name]?true:false);
  }
 
},[data[name]]);

  return (
    <div className={className + " relative"}> 
    <div className={`flex items-center ${className}`}>
      <input name={name} type="checkbox" className="hidden" {...register(name, { required:errorMsg })}/>
      <span className={`w-5 h-5 cursor-pointer transition-all rounded-md border-2 ${checked?"border-blue":"border-gray-dark"}  flex items-center justify-center`} onClick={()=>{setChecked(!checked);setValue(name,!checked)}}>
        {checked?<TickIcon></TickIcon>:<></>}
      </span>
      <label
        for="checked-checkbox"
        className="ml-2 rtl:mr-2 rtl:ml-auto text-base font-medium text-gray-dark select-none"
      >
        {label}
      </label>
      
    </div>
{errors && errors[name] && <small className="text-red-2 absolute top-full">{errors[name].message}</small>}
</div>
  );
};

export default CheckBox;
