import ConfirmModal from "@/src/components/Common/Modals/ConfirmModal";
import { render, unmountComponentAtNode } from "react-dom"
import { createRoot } from 'react-dom/client';

export const modal = {

      remove: () => {
        modal.root.unmount()
        modal.currentToast = false
        if(modal.timeout){
          clearTimeout(modal.timeout)
          modal.timeout = null
        }
      },
      root:null,
      currentToast: false,
      timeout: null,
      notify: ( message ,title, onConfirm=null, options = null) =>{
        
        let duration = 5

        if( options ){
          if( options.duration)
            duration = options.duration
        } 
    
        if(modal.currentToast) { 
          modal.remove()
    
        }
        let trasitionPercentage = 0.3*(100/duration)
        const container = document.getElementById('toast-container');
        modal.root = createRoot(container); 
        // createRoot(container!) if you use TypeScript
        modal.root.render(<ConfirmModal 
          message={message} 
          title={title}
          duration={duration}
          onConfirm={onConfirm} {...options} />);
        modal.currentToast = true
      //   modal.timeout = setTimeout( modal.remove, duration*1000)
      }
    }