import React from "react";
import ToastNormalIcon from "@/public/icons/ToastNormalIcon.svg";
import ToastWarningIcon from "@/public/icons/ToastWarningIcon.svg";
import ToastSuccessIcon from "@/public/icons/ToastSuccessIcon.svg";
import ToastDangerIcon from "@/public/icons/ToastDangerIcon.svg";
import CloseToastIcon from "@/public/icons/CloseToastIcon.svg";
import { toast } from "@/utils/toast";
import Container from "../../Layout/Container";
const Toast = (props) => {
  const { title='',message='', transitionPercentage = 0,type=1 } = props;
  console.log(props,toast);

  let titleColor = "black";
  let icon = <ToastNormalIcon></ToastNormalIcon>;
  let bgImage = "bg-normal.png";
  switch (type) {
    case 1:// normal
      titleColor = "black";
      icon = <ToastNormalIcon></ToastNormalIcon>;
      bgImage = "bg-normal.png";
      break;
    case 2:// danger
      titleColor = "red-2";
      icon = <ToastDangerIcon></ToastDangerIcon>;
      bgImage = "bg-danger.png";
      break;
    case 3:// info
      titleColor = "blue-2";
      icon = <ToastSuccessIcon className="[&>path]:stroke-blue-2"></ToastSuccessIcon>;
      bgImage = "bg-info.png";
      break;
    case 4:// warning
      titleColor = "orange";
      icon = <ToastWarningIcon></ToastWarningIcon>;
      bgImage = "bg-warning.png";
      break;
    case 5:// success
      titleColor = "green-2";
      icon = <ToastSuccessIcon></ToastSuccessIcon>;
      bgImage = "bg-success.png";
      break;
  }
  return (
    <Container>
    <div className="bg-white drop-shadow-lg w-[300px] h-auto mt-24 rounded-lg z-50 -mr-2.5 ml-auto rtl:mr-auto rtl:-ml-2.5 ">
      <img src={`/images/${bgImage}`} className="w-full h-6"></img>
      <div className="flex items-center px-4 py-2.5">
        <span className={`[&>path]:stroke-${titleColor}`}>{icon}</span>
        <h6 className={`text-base pl-5 rtl:pl-0 rtl:pr-5 text-${titleColor}`}>{title}</h6>
        <CloseToastIcon className="ml-auto rtl:ml-0 rtl:mr-auto cursor-pointer" onClick={()=>toast.remove()}></CloseToastIcon>
      </div>
      <div
        className={`h-[1px] w-[${transitionPercentage}%] bg-gray-light-6 animate-grow `}
      ></div>
      <div className="px-4 pb-4 pt-2 text-sm font-open-sans rtl:font-dana-r text-gray-dark-2">
      {message}
      </div>
    </div>
    </Container>
  );
};

export default Toast;
