import Link from "next/link";
import React from "react";

export default function IconButton(props) {
  let info = {
    label: props.label ? props.label : null,
    icon: props.icon,
    backgroundColor: "bg-transparent",
    color: "text-white",
    borderColor: "border-transparent",
    backgroundColorHover: "hover:bg-transparent",
  };
  let style =
    "rounded-md whitespace-nowrap inline-flex items-center p-2 h-full transition group " +
    (props.fullWidth ? "block justify-center w-full min-w-max " : "");
  let svgStyle = "";
  switch (props.type) {
    case "PRIMARY":
      style += "bg-blue hover:bg-blue-2 disabled:bg-blue-2 text-white";
      svgStyle += "[&>path]:stroke-white";
      break;
    case "SECONDARY":
      style += "bg-orange hover:bg-white text-white hover:text-orange";
      svgStyle += "[&>path]:stroke-white group-hover:[&>path]:stroke-orange";
      break;
    case "DEFAULT":
      style += "bg-transparent hover:bg-transparent text-black";
      svgStyle += "[&>path]:stroke-black";
      break;
    case "OUTLINE":
      style +=
        "bg-transparent hover:bg-transparent text-black border-gray-light border hover:border-blue-2";
      svgStyle += "[&>path]:stroke-black";
      break;
    case "DANGER":
      style +=
        "bg-red-2 hover:bg-white text-white hover:text-gray-dark border-transparent hover:border-red-2 border-[1px]";
      svgStyle += "[&>path]:stroke-white";
      break;
    case "LINK":
      style +=
        "bg-transparent text-black hover:text-gray-dark border-transparent hover:border-transparent border-[1px]";
      svgStyle += "[&>path]:stroke-black";
      break;
    case "SUCCESS":
      style +=
        "bg-green-2 hover:bg-green text-white hover:text-white border-transparent border-[1px]";
      svgStyle += "[&>path]:stroke-white";
      break;
    case "LIGHT":
      style += "bg-white hover:bg-light-gray text-black-2 hover:text-black";
      svgStyle += "[&>path]:stroke-black";
      break;
    case "DARK":
      style += "bg-gray-dark-2 hover:bg-gray-light-2 text-white hover:text-white";
      svgStyle += "[&>path]:stroke-white";
      break;
    case "GOOGLE":
      style += "bg-black hover:bg-black-base text-white border-transparent";
      break;
  }

  let { className, btnType='button',...rest } = props;
  className = className ? className : "";

  return props.link ? (
    <Link className={className} prefetch={false} target={props.target?props.target:"_self"} href={props.link || ""} {...rest} aria-label={info.label}>
      <div className={`${props.type=="GOOGLE"?"h-10":"h-8"} ${props.fullWidth ? "block" : "inline-flex"}`}>
        <button className={style} type={btnType} disabled={props.disabled}>
          {info.icon && <info.icon className={svgStyle}></info.icon>}
          {info.label ? (
            <span className={`inline-block ${info.icon?"ml-2 rlt:ml-0 rtl:mr-2":""}`}>{info.label}</span>
          ) : (
            <></>
          )}
        </button>
      </div>
      <span className="sr-only">Btn to Link for {info.label}</span>
    </Link>
  ) : (
    <div
      className={`${className} ${props.type=="GOOGLE"?"h-10":"h-8"} ${props.fullWidth ? "block" : "inline-flex"}`}
      {...rest}
    >
      <button className={style} type={btnType} disabled={props.disabled}>
        {info.icon && <info.icon className={svgStyle}></info.icon>}
        {info.label ? (
          <span className={`inline-block ${info.icon?"ml-2 rlt:ml-0 rtl:mr-2":""}`}>{info.label}</span>
        ) : (
          <></>
        )}
      </button>
    </div>
  );
}
