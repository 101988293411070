import React, { useState } from "react";
import RankingGoldIcon from "@/public/icons/RankingGoldIcon.svg";
import RegularOffer from "@/public/icons/RegularOffer.svg";
import ExceptionalOffer from "@/public/icons/ExceptionalOffer.svg";
import LocationIcon from "@/public/icons/LocationIcon.svg";
import VerifiedIcon from "@/public/icons/VerifiedIcon.svg";
import HalalIcon from "@/public/icons/HalalIcon.svg";
import HintIcon from "@/public/icons/HintIcon.svg";
import CloseHintIcon from "@/public/icons/CloseHintIcon.svg";
import Link from "next/link";
import ImageMultiSrc from "../Main/ImageMultiSrc";
import useTranslation from "@/hooks/useCustomTranslation";

const AdsCard = (props) => {
  //console.log('AdsCard' , props);
  const {
    title = "",
    desc = "",
    category = "",
    link = "",
    subCategory = "",
    photo = "",
    location = "",
    displayType = 1,
    type = 1,
    slug = "",
    categorySlug = "",
    subCategorySlug = "",
    categoryId = null,
    subCategoryId = null,
    isHalal = false,
    supplier = "",
    supplierSlug = "",
    supplierIsCompany = false,
    supplierIsVerified = false,
    className = "",
    hasShadow = false,
    layers = [],
  } = props;
  const [hintOpen, setHintOpen] = useState(false);
  const [hoverType, setHoverType] = useState(false);
  const { t } = useTranslation("common");

  //console.log('AdsCard.layers:' , layers);

  let headerBgColor = "gray-light";
  let headerTextColor = "black";
  let headerTitle = "Regular Offer";
  let headerIcon = <RegularOffer></RegularOffer>;
  let typeText = "Sale";
  let typeBgColor = "red";

  let catLink = "/ads";

  switch (displayType) {
    case 1: //REGULAR
      headerBgColor = "gray-light";
      headerTextColor = "black";
      headerTitle = t("ad_display_type_regular");
      headerIcon = <RegularOffer></RegularOffer>;
      break;
    case 2: //SPECIAL
      headerBgColor = "black-2";
      headerTextColor = "white";
      headerTitle = t("ad_display_type_special");
      headerIcon = <RankingGoldIcon></RankingGoldIcon>;
      break;
    case 3: //EXCEPTIONAL
      headerBgColor = "purple";
      headerTextColor = "white";
      headerTitle = t("ad_display_type_exceptional");
      headerIcon = <ExceptionalOffer></ExceptionalOffer>;
      break;
  }

  switch (type) {
    case 1: // purchase
      typeText = t("ad_type_purchase_request");
      typeBgColor = "green-2";
      break;
    case 2: // ready to ship
      typeText = t("ad_type_ready_to_ship");
      typeBgColor = "blue-2";
      break;
    case 3: // sales
      typeText = t("ad_type_sales_offer");
      typeBgColor = "red";
      break;
  }
  return (
    <div
      className={`overflow-hidden min-h-[350px] select-none ${
        hasShadow ? "shadow-lg" : ""
      } rounded-lg ${className}`}
    >
      <div className="w-full bg-white relative mt-10 rounded-lg">
        <div
          className={`absolute -top-9 p-1 px-3 pb-20 left-0 rtl:left-auto rtl:right-0 rounded-lg inline-flex bg-${headerBgColor} w-full items-center -z-10`}
        >
          {headerIcon}
          <span
            className={`text-${headerTextColor} text-lg pl-3 rtl:pl-autp rtl:pr-3`}
          >
            {headerTitle}
          </span>
        </div>
        <span
          className="absolute -top-[30px] right-2 rtl:left-2 rtl:right-auto ml-auto cursor-pointer"
          onClick={() => setHintOpen(!hintOpen)}
        >
          {!hintOpen ? (
            <HintIcon
              className={`[&>path]:stroke-${headerTextColor}`}
            ></HintIcon>
          ) : (
            <CloseHintIcon
              className={`[&>path]:stroke-${headerTextColor}`}
            ></CloseHintIcon>
          )}
        </span>

        <div className="p-2 rounded-xl w-full relative overflow-hidden">
          <div
            className={`${
              hintOpen ? "opacity-100" : "opacity-0 -z-50"
            } absolute w-full h-full transition-all bg-white bg-opacity-90 px-3 py-2`}
          >
            <strong className="flex items-center font-bold text-base text-black">
              {t("ad_supplier")}
              {supplierIsCompany && supplierIsVerified && (
                <VerifiedIcon className="scale-75"></VerifiedIcon>
              )}
            </strong>
            <Link
              href={
                supplierIsCompany
                  ? "/companies/" + supplierSlug
                  : "/users/" + supplierSlug
              }
              className="text-black text-sm"
            >
              {supplier}
            </Link>
            {isHalal && (
              <>
                <strong className="flex items-center font-bold text-base text-black pt-2">
                  {t("ad_supplier_certifications")}
                </strong>
                {isHalal && (
                  <HalalIcon className="scale-50 -ml-3 rtl:ml-auto rtl:-mr-3 -mt-2"></HalalIcon>
                )}
              </>
            )}
          </div>
          <Link href={link}>
            <ImageMultiSrc
              src={photo}
              alt={title}
              className="w-full rounded-lg h-[138px] object-cover"
            />
          </Link>
        </div>
        <div className="px-2 pb-2">
          <span className="font-open-sans rtl:font-dana-r block pb-2 text-xs text-gray-dark h-10">

          {/* Categories Link */}
          {layers && layers.map( (item ,index) => (
              (catLink = catLink + "/" + item.slug) &&
              (index == 0 || index == 2) && (
              <>
              <Link
                className="hover:text-blue-2 transition-all"
                href={ catLink }
              >
                {item.name}
              </Link> 
              { index == 0 ?  " . " : "" }
              </>
              )
          ))}    

          </span>
          <div className="relative">
            <div className="flex absolute w-full -left-5 rtl:-right-5 rtl:left-auto group transition-all cursor-default">
              <div
                className={`bg-${typeBgColor} text-white group-focus:w-1 group-hover:w-1 group-focus:text-transparent group-hover:text-transparent transition-all duration-500 ease-out px-5 pr-2 rtl:pr-5 rtl:pl-2 py-1 text-ellipsis overflow-hidden whitespace-nowrap w-52 max-w-fit`}
              >
                {typeText}
              </div>
              <div className="bg-black-2 group-hover:text-gray-light text-transparent group-hover:w-52 w-10 max-w-fit h-8 px-2 py-1 group-hover:inline-flex inline-block transition-all duration-500 rounded-r-md rtl:rounded-r-none rtl:rounded-l-md text-ellipsis overflow-hidden whitespace-nowrap">
                <LocationIcon className="group-hover:mr-2 stroke-white block"></LocationIcon>{" "}
                {location}
              </div>
            </div>
          </div>
          <div className="mt-10 h-32">
            <Link href={ link } className="block">
              <h5 className="text-md font-semibold font-open-sans rtl:font-dana-r capitalize">
                {title}
              </h5>

              <p
                className="font-open-sans rtl:font-dana-r text-sm text-justify text-gray-dark my-5 text-ellipsis overflow-hidden"
                style={{
                  display: "-webkit-box",
                  "-webkit-line-clamp": "3",
                  "-webkit-box-orient": "vertical",
                }}
              >
                {desc}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsCard;
