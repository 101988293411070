import React from 'react'
import Link from "next/link";

const SectionTitle = (props) => {
  return (
    <div className="inline-flex items-center lg:mb-16 mb-8">
        <hr className="lg:w-14 w-6 h-0.5 border-0 bg-black"></hr>
        <h4 className="font-open-sans rtl:font-dana-r lg:text-4xl text-2xl text-black ml-2 rtl:ml-auto rtl:mr-2 font-light">
          {props.titleLink && (
            <Link href={props?.titleLink} className="" >
              {props.title}
            </Link>
          )}
          {!props.titleLink && props?.title && (
              props.title
          )}
          </h4>
      </div>
  )
}

export default SectionTitle