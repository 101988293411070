import React from 'react'

const ToastContainer = (props) => {
  return (
      <div id="toast-container" className="toast-container z-[1000] fixed w-screen top-0 right-0">
        
    </div>
  )
}

export default ToastContainer