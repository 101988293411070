import { useState, useEffect } from 'react';
import { useTranslation as useNextTranslation } from 'react-i18next';
import i18next from 'i18next';

const useTranslation = (namespace) => {
  
  if (process.env.NODE_ENV === 'production') 
  {
    // Production mode
    const { t, i18n } = useNextTranslation(namespace);

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return {
      t,
      changeLanguage,
    };

  } else {
    // Development mode
    const defaultTranslate = (key, options) => {
      return ``;
    };
  
    const [t, setT] = useState(() => defaultTranslate);
  
    const { i18n } = useNextTranslation(namespace);
  
    useEffect(() => {
      const loadTranslations = async () => {
        try {
          var lang = i18next.language.length == 2 ? i18next.language : 'en';
          i18next.changeLanguage(lang);
          const { default: translations } = await import(`../public/locales/${lang}/${namespace}.json`);
          i18next.addResources(lang, namespace, translations);
          setT(() => {
            const fixedT = i18next.getFixedT(lang, namespace);
            // Check if translation result matches the key
            return (key, options) => {
              key = key.replace(/[\s-]/g, '_');
              const result = fixedT(key.toLowerCase(), options);
              return result === key.toLowerCase() ? `${lang.toUpperCase()}|SIRAAF|${namespace.toUpperCase()}|${key.toUpperCase()}` : result;
            };
          });
        } catch (error) {
          console.error(`Failed to load translations for namespace "${namespace}":`, error);
          setT(() => defaultTranslate); // Fallback to default if loading fails
        }
      };
  
      loadTranslations();
    }, [namespace, i18n.language]); // Reload translations if the namespace or language changes
  
    const changeLanguage = (lng) => {
      i18next.changeLanguage(lng);
      // Force reload translations after language change
      setT(() => i18next.getFixedT(lng, namespace));
    };
  
    return {
      t,
      changeLanguage,
    };
  }
};

export default useTranslation;
