import { Fragment, useEffect, useState } from "react";
import HeaderLogo from "@/public/icons/LogoType2.svg";
import HeaderLogoWhite from "@/public/icons/HeaderLogoWhite.svg";
import IconButton from "../../Common/Buttons/IconButton";
import FlagButton from "../../Common/Buttons/FlagButton";
import ProfileIcon from "@/public/icons/ProfileIcon.svg";
import MenuIcon from "@/public/icons/MenuIcon.svg";
import ListIcon from "@/public/icons/ListIcon.svg";
import AddAdvIcon from "@/public/icons/AddAdvIcon.svg";
import CloseIcon from "@/public/icons/CloseIcon.svg";
import LinkedinIcon from "@/public/icons/LinkedinIcon.svg";
import InstagramIcon from "@/public/icons/InstagramIcon.svg";
import TwitterIcon from "@/public/icons/TwitterIcon.svg";
import SearchIcon from "@/public/icons/SearchIcon.svg";
import MultiSearchInput from "../../Common/Inputs/MultiSearchInput";
import LogoutMenuIcon from "@/public/icons/LogoutMenuIcon.svg";
import Link from "next/link";
import { isLogin } from "@/utils/functions";
import Input from "../../Common/Inputs/Input";
import { FormProvider, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { modal } from "@/utils/modal";
import Cookies from "universal-cookie";
import useTranslation from "@/hooks/useCustomTranslation";
import Image from "next/image";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const router = useRouter();
  const methods = useForm({
    mode: "onSubmit",
  });
  const { register, handleSubmit, errors, watch, formState, reset } = methods;

  const { t } = useTranslation("header","common");

  const onSubmitSearch = async (data) => {
    let searchKey="";
    if(data.search_key!=""){
      searchKey=data.search_key;
    }
    if(data.search_key_mobile!=""){
      searchKey=data.search_key_mobile;
    }
    router.push({
      pathname: "/search",
      query: {
        search_key:searchKey
      },
    });
  };

  

  return (
    <header className="bg-transparent">
      <nav
        className="mx-auto flex items-center justify-between"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 items-center">
          <Link
            href="/"
            className="pr-4 items-center inline-flex xl:border-r-2 rtl:xl:border-l-2 rtl:border-r-0 rtl:pl-4 rtl:pr-0 xl:border-gray-light"
          >
            <span className="sr-only">Siraaf</span>
            {/* <HeaderLogo></HeaderLogo> */}
            <Image width={160} height={35} src="/images/logo2xl.png"></Image>
          </Link>
          <FlagButton className="hidden xl:inline-flex"></FlagButton>
          <FormProvider {...methods}>
            <form
              className="hidden xl:flex"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmitSearch)}
            >
              <Input
                placeholder={t("search")}
                Icon={SearchIcon}
                iconStyle={
                  "absolute cursor-pointer -right-2 rtl:-left-2 rtl:right-auto rtl:right-auto top-2 [&>path]:stroke-black"
                }
                className={
                  "ml-4 rtl:ml-0 rtl:mr-4 pr-10 rtl:pr-4 rtl:pl-10 bg-transparent hover:border-gray-light-2 transition-all"
                }
                name={"search_key"}
              ></Input>
            </form>
          </FormProvider>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex xl:gap-x-8 lg:gap-x-4 mr-10 rtl:mr-0 rtl:ml-10 transition-all">
          <div className="relative group transition hover:text-blue text-gray-dark text-center">
            <Link href="/ads">
              <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
              {t("all_ads")}
            </Link>
            <div
              id="dropdownHover"
              class="z-30 absolute -left-2 rtl:-right-2 top-full hidden group-hover:flex bg-gray-light-3 divide-y divide-gray-100 rounded-lg rounded-tl-none shadow-md w-56"
            >
              <ul
                class="py-2 text-sm text-left rtl:text-right text-gray-700"
                aria-labelledby="dropdownHoverButton"
              >
                <li>
                  <Link
                    href="/ads/purchase-request"
                    class="block py-2 px-4 text-base text-black hover:text-blue"
                  >
                    {t("purchase_request")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/ads/sell-offer"
                    class="block py-2 px-4 text-base text-black hover:text-blue"
                  >
                    {t("sales_order")}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/ads/ready-to-ship"
                    class="block py-2 px-4 text-base text-black hover:text-blue"
                  >
                    {t("ready_to")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <Link
            href="/companies"
            className="relative group transition hover:text-blue text-gray-dark text-center"
          >
            <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
            {t("companies")}
          </Link>
          {/* <Link
            href="/pricing"
            className="relative group transition hover:text-blue text-gray-dark text-center"
          >
            <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
            {t("pricing")}
          </Link> */}
          <Link
            href="/blogs"
            className="relative group transition hover:text-blue text-gray-dark text-center"
          >
            <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
            {t("blogs")}
          </Link>
          <Link
            href="/about-us"
            className="relative group transition hover:text-blue text-gray-dark text-center"
          >
            <span className="absolute -left-2 rtl:-right-2 transition top-2.5 rounded-full bg-transparent group-hover:bg-blue w-1 h-1 inline-block"></span>
            {t("about_us")}
          </Link>
        </div>
        <div className="hidden lg:flex lg:justify-end">
          {isLogin() ? (
            <>
              <span className="group relative">
                <IconButton
                  link={"/profile/dashboard"}
                  type="PRIMARY"
                  label={""}
                  icon={ProfileIcon}
                  className={"mr-1 rtl:mr-auto rtl:ml-1"}
                ></IconButton>
                <div
                  id="dropdownHover"
                  class="z-30 absolute left-0 rtl:left-auto rtl:right-0 top-full hidden group-hover:flex bg-gray-light-3 divide-y divide-gray-100 rounded-lg rounded-tl-none rtl:rounded-tr-none shadow-md w-44 transition-all"
                >
                  <ul
                    class="py-2 text-sm text-left rtl:text-right text-gray-700"
                    aria-labelledby="dropdownHoverButton"
                  >
                    <li>
                      <Link
                        href="/profile/dashboard"
                        class="block py-2 px-4 text-base text-black hover:text-blue"
                      >
                        {t("my_profile")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/profile/ads"
                        class="block py-2 px-4 text-base text-black hover:text-blue"
                      >
                        {t("my_ads")}
                      </Link>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          modal.notify(
                            t("modal_confirm_are_you_sure_desc",{ns:"common"}),
                            t("modal_confirm_are_you_sure",{ns:"common"}),
                            () => {
                              new Cookies().remove("user_token", { path: "/" });
                              router.push("/");
                              modal.remove();
                            },
                            {
                              "yesText":t("modal_confirm_yes",{ns:"common"}),
                              "noText":t("modal_confirm_no",{ns:"common"}),
                            }
                          );
                        }}
                        class="block py-2 cursor-pointer px-4 text-base text-red-2 hover:text-blue"
                      >
                        {t("sign_out")}
                      </span>
                    </li>
                  </ul>
                </div>
              </span>
              <IconButton
                link={"/profile/ads"}
                type="SECONDARY"
                label={t("submit_ad")}
                icon={AddAdvIcon}
              ></IconButton>
            </>
          ) : (
            <IconButton
              link={"/auth/login"}
              type="PRIMARY"
              label={t("join_free")}
              icon={ProfileIcon}
              className={"mr-1"}
            ></IconButton>
          )}
        </div>
      </nav>
      <div
        className={
          "w-screen bg-white max-h-screen overflow-y-scroll fixed left-0 rtl:left-auto rtl:right-0 z-[2000] p-4 transition-all duration-200 ease-in-out h-screen flex flex-col " +
          (mobileMenuOpen ? "top-0" : "top-full")
        }
      >
        <div className="flex items-center">
          <a
            href="/"
            className=" items-center inline-flex mr-5 rtl:ml-3 rtl:mr-0"
          >
            <span className="sr-only">Siraaf</span>
            {/* <HeaderLogoWhite className={"[&>g]:fill-black"}></HeaderLogoWhite> */}
            <Image width={160} height={35} src="/images/logo.png"></Image>
          </a>
          <FlagButton className={"text-black border-orange"}></FlagButton>
          <CloseIcon
            className={"ml-auto rtl:ml-0 rtl:mr-auto [&>path]:stroke-black"}
            onClick={() => setMobileMenuOpen(false)}
          ></CloseIcon>
        </div>
        <FormProvider {...methods}>
          <form
            className="mt-4"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitSearch)}
          >
            <Input
              placeholder={t("search")}
              Icon={SearchIcon}
              iconStyle={
                "absolute right-4 rtl:right-auto rtl:left-4 top-2 [&>path]:stroke-black  w-8 h-full"
              }
              className={"pr-10 rtl:pr-auto rtl:ml-10 bg-black text-white"}
              name={"search_key_mobile"}
            ></Input>
          </form>
        </FormProvider>
        <div className="flex items-center justify-between mt-4">
          {isLogin() ? (
            <>
              <IconButton
                link={"/profile/dashboard"}
                type="PRIMARY"
                label={t("my_profile")}
                icon={ProfileIcon}
              ></IconButton>
              <span
                onClick={() => {
                  new Cookies().remove("user_token", { path: "/" });
                  router.push("/");
                }}
                className="flex cursor-pointer gap-2 mr-4 rtl:mr-0 rtl:ml-4"
              >
                <LogoutMenuIcon></LogoutMenuIcon>
                <span className="text-base text-red-2">{t("sign_out")}</span>
              </span>
            </>
          ) : (
            <IconButton
              link={"/auth/login"}
              type="PRIMARY"
              label={t("join_free")}
              icon={ProfileIcon}
              className={"w-full"}
              fullWidth
            ></IconButton>
          )}
        </div>

        <ul className="text-black py-5 px-8 flex flex-col gap-y-3 -mx-4">
          <li>
            <Link href="/ads">{t("all_ads")}</Link>
          </li>
          <li>
            <Link href="/ads/purchase-request">{t("purchase_request")}</Link>
          </li>
          <li>
            <Link href="/ads/ready-to-ship">{t("ready_to")}</Link>
          </li>
          <li>
            <Link href="/ads/sell-offer">{t("sales_order")}</Link>
          </li>
          <li>
            <Link href="/companies">{t("companies")}</Link>
          </li>
        </ul>
        {isLogin() ? (
          <IconButton
            link={"/profile/ads"}
            type="SECONDARY"
            label={t("submit_ad")}
            fullWidth={true}
            className={"mb-6"}
            icon={AddAdvIcon}
          ></IconButton>
        ) : (
          <></>
        )}
        <ul className="text-black py-5 px-8 flex flex-col gap-y-3 border-b border-t border-gray-light-3 -mx-4">
          <li>
            <Link href="/about-us">{t("about_us")}</Link>
          </li>
          <li>
            <Link href="/contact-us">{t("contact_us")}</Link>
          </li>
        </ul>
        <ul className="text-black py-5 px-8 flex flex-col gap-y-3 -mx-4">
          <li>
            <Link href="/blogs">{t("blogs")}</Link>
          </li>
          <li>
            <Link href="/faq">{t("faq")}</Link>
          </li>
        </ul>
        <div className="mt-auto">
          <ul className="flex items-center flex-row gap-x-5 pl-5 rtl:pr-5 rtl:pl-auto mt-4">
            <li>
              <a href="#">
                <LinkedinIcon></LinkedinIcon>
              </a>
            </li>
            <li>
              <a href="#">
                <InstagramIcon></InstagramIcon>
              </a>
            </li>
            <li>
              <a href="#">
                <TwitterIcon></TwitterIcon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
