import React, { useEffect, useRef, useState } from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Arrow from "@/public/icons/Arrow.svg";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useRouter } from "next/router";

const defaultBreakpoint={
  540: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
  700: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
  2048: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
};

const BannerSlider = (props) => {
  const { items = [], loop = false,BaseComp=<></>,breakpoints=defaultBreakpoint } = props;
  // const swiper= useSwiper();
  const router=useRouter();
  const [activeSlide, setActiveSlide] = useState(0);
  const [bullets, setBullets] = useState([]);
  const [lang, setLang] = useState("en");
  const swiperRef = useRef();

  useEffect(()=>{
    if(swiperRef && swiperRef.current && swiperRef.current.pagination && swiperRef.current.pagination.bullets!=bullets){
        setBullets(swiperRef.current.pagination.bullets);
    }
  },[swiperRef]);

  useEffect(()=>{
    if(router.locale !=lang){
      setLang(router.locale);
    }
  },[router.locale]);

  return (
    <div id="hero-slider" className="relative">
      {(loop || (bullets.length>1 && activeSlide !== items.length - 1)) && (
        <div
          onClick={() => swiperRef.current.slideNext()}
          className="bg-white bg-opacity-20 md:inline-flex hidden items-center justify-center group h-10 w-10 z-20 cursor-pointer rounded-l-md absolute -right-0 top-[calc(50%_-_20px)]"
        >
          <Arrow className="group-hover:[&>path]:stroke-blue rotate-180"></Arrow>
        </div>
      )}
      {(loop || (bullets.length>1 && activeSlide > 0)) && (
        <div
          onClick={() => swiperRef.current.slidePrev()}
          className="bg-white bg-opacity-20 lg:inline-flex hidden items-center justify-center group h-10 w-10 z-20 cursor-pointer rounded-r-md absolute -left-0 top-[calc(50%_-_20px)]"
        >
          <Arrow className="group-hover:[&>path]:stroke-blue"></Arrow>
        </div>
      )}
      {(
        <div className="absolute  lg:-left-5 2xl:-left-6 rtl:left-auto rtl:lg:-right-5 rtl:2xl:-right-6 lg:top-0 top-full pt-4 lg:pt-0 flex items-center justify-center w-full lg:w-auto lg:h-full">
          <div className="dots flex lg:flex-col items-center gap-1 ">
            {[
              ...Array(bullets.length).keys(),
            ].map((idx) => {
              return (
                <span
                  key={idx}
                  onClick={() => {
                    swiperRef.current.slideTo(idx);
                  }}
                  className={
                    "rounded-lg " +
                    (activeSlide !== idx
                      ? "lg:h-3 lg:w-1.5 w-3 h-1.5 bg-gray-light"
                      : "lg:h-5 lg:w-1.5 w-5 h-1.5 bg-gray-dark")
                  }
                ></span>
              );
            })}
          </div>
        </div>
      )}
      <Swiper
        modules={[Navigation,Pagination,Autoplay]}
        navigation
        key={lang+"-swiper"}
        dir={router.locale=="fa"?"rtl":"ltr"}
        pagination
        className=""
        autoplay={{
          delay: 4500,
          pauseOnMouseEnter:true,
          disableOnInteraction: true,
        }}
        loop={loop}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        // breakpoints={breakpoints}
        spaceBetween={30}
        slidesPerView={1}
        updateOnWindowResize
        onSlideChange={() => setActiveSlide(swiperRef.current.realIndex)}
      >
        {items.map((item, idx) => (
          <SwiperSlide key={idx}>
            <BaseComp {...item}></BaseComp>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BannerSlider;
